import { getImagePath } from '@common/helpers/images';
import { Agent } from '@sly/core/types';
import ProfileContent from 'sly/agentsRegionPage/AgentsRegionPageContent/AgentsRegionPageData/AgentsRegionPageAgents/AgentTile/ProfileContent';
import ProfileImage from 'sly/agentsRegionPage/AgentsRegionPageContent/AgentsRegionPageData/AgentsRegionPageAgents/AgentTile/ProfileImage';
import Link from 'sly/system/Link';

export interface AgentTileProps {
  agent: Agent;
}

export default function AgentTile({ agent }: AgentTileProps) {
  let imageUrl = null;
  if (
    agent.gallery &&
    agent.gallery.images &&
    agent.gallery.images.length > 0
  ) {
    imageUrl = getImagePath(
      encodeURI(agent.gallery.images[0].path.replace(/\.jpe?g$/i, '.jpg'))
    );
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Link
      href={agent.info.url}
      data-tp-id='gen-Link-1005d6af-29ca-4de2-ba98-dc0c615100e9'
    >
      <div data-tp-id='gen-div-cc9d21a0-94b0-4c27-9125-0817864becdc'>
        <ProfileImage
          src={imageUrl}
          recentFamiliesHelped={agent.info.recentFamiliesHelped}
          displayName={agent.info.displayName}
        />
        <ProfileContent
          displayName={agent.info.displayName}
          rating={agent.aggregateRating}
          url={agent.info.url}
          operatingIn={agent?.info?.serviceArea?.operatingIn ?? ''}
        />
      </div>
    </Link>
  );
}
