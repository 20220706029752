import Tab from '@react/shared/components/Tab';
import Tabs from '@react/shared/components/Tabs';
import { Agent } from '@sly/core/types';
import { MouseEvent, useState } from 'react';
import ExploreByCommunitySection from 'sly/hubPage/components/HubDynamicContent/ExploreByEntity/ExploreByCommunitySection';
import { useApi } from 'sly/services/api';
import Heading from 'sly/system/Heading';

import { Entity } from './ExploreByCommunity.types';
import { BoostedCommunity } from './ExploreByCommunitySection/ExploreByCommunitySection.types';

interface TabListItem {
  id?: number;
  slugs: string;
  title: string;
}

export interface TabList {
  id?: number;
  title: string;
  tabList: TabListItem[];
}

export interface ExploreByCommunitiesProps extends TabList {
  entity: Entity;
}

export default function ExploreByCommunity({
  title,
  tabList,
  entity,
}: ExploreByCommunitiesProps) {
  const {
    apiClient: { store },
  } = useApi() as unknown as {
    apiClient: {
      store: {
        getState: () => Record<string, unknown>;
        getRequestInfo: (
          endpoint: string,
          params: string
        ) => Record<string, unknown>;
      };
      api: Record<string, unknown>;
    };
  };

  const dataStore: Record<string, BoostedCommunity[] | Agent[]> = {};
  for (let i = 0; i < tabList?.length; i++) {
    const tab = tabList[i];
    const { slugs, title } = tab;
    const arrSlugs = slugs.split(/\s+/);

    if ('communities' === entity) {
      dataStore[title] = store.getRequestInfo(
        'getSearchPage',
        JSON.stringify({
          'filter[id]': arrSlugs,
          include: 'community',
          exp: 'or',
          'page-size': arrSlugs.length,
        })
      )?.normalized as BoostedCommunity[];
    } else if ('agents' === entity) {
      dataStore[title] = store.getRequestInfo(
        'getAgentsNew',
        JSON.stringify({
          'filter[slug]': arrSlugs.join(','),
          include: 'address,gallery,gallery.images',
          'page-size': arrSlugs.length,
        })
      )?.normalized as Agent[];
    }
  }

  const tabs = Object.keys(dataStore);

  const [activeTab, setActiveTab] = useState<string>(tabs?.[0]);

  if (tabs.length === 0) {
    return null;
  }

  const onClick = (e: MouseEvent<HTMLSpanElement>) => {
    setActiveTab((e.target as Element).id);
  };

  return (
    <section className='px-8 pt-12 md:pt-20'>
      <div className='mx-auto max-w-5xl'>
        <Heading
          font='font-t-l'
          className='mb-4 w-full'
          data-tp-id='gen-Heading-762446cc-b149-4f1c-8f6f-c626ef89d7d3'
        >
          {title}
        </Heading>
        <div className='w-full'>
          <Tabs activeTab={activeTab} className='px-0'>
            {tabs.map((tab) => {
              if (
                !dataStore[tab as keyof typeof dataStore] ||
                !dataStore[tab as keyof typeof dataStore].length
              )
                return undefined;
              return (
                <Tab
                  key={tab}
                  id={tab}
                  onClick={onClick}
                  className='border-black normal-case text-slate-base hover:cursor-pointer'
                >
                  {tab?.toLowerCase()?.charAt(0).toUpperCase() +
                    tab?.toLowerCase()?.slice(1)}
                </Tab>
              );
            })}
          </Tabs>
        </div>
        <div>
          {tabs.map((tab) => (
            <ExploreByCommunitySection
              entityData={dataStore[tab as keyof typeof dataStore]}
              entity={entity}
              key={tab}
              active={activeTab === tab}
              tab={tab}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
